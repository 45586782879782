import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { WaitingRoomConferenceService } from '../../waiting-room-conference.service';

import * as WaitingRoomActions from '../waiting-room.actions';
import { takeUntilKnockFinished } from '../waiting-room.operators';

@Injectable()
export class WaitingRoomGuestAdmissionEffects {
  guestAdmitted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaitingRoomActions.knockSuccess),
      switchMap(() => {
        return this.waitingRoomConference.onGuestAdmitted().pipe(
          takeUntilKnockFinished(this.actions$),
          map(data =>
            WaitingRoomActions.guestAdmitted({
              token: data.token,
              appointmentId: data.appointmentId,
            }),
          ),
        );
      }),
    );
  });

  guestAdmissionDenied$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaitingRoomActions.knockSuccess),
      switchMap(() => {
        return this.waitingRoomConference.onGuestAdmissionDenied().pipe(
          takeUntilKnockFinished(this.actions$),
          map(() => WaitingRoomActions.guestAdmissionDenied()),
        );
      }),
    );
  });

  guestKnockRejected$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WaitingRoomActions.knockSuccess),
      switchMap(() => {
        return this.waitingRoomConference.onGuestKnockRejected().pipe(
          map(data => {
            return WaitingRoomActions.knockRejected({
              reason: data.reason,
            });
          }),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private waitingRoomConference: WaitingRoomConferenceService,
  ) {}
}
