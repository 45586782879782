import { createAction } from '@common/utils';
import { props } from '@ngrx/store';
import { ConferenceConfig } from '../../conference';
import { KnockData, waitingRoomFeatureKey } from './waiting-room.model';

export const init = createAction(
  `/${waitingRoomFeatureKey}/init`,
  props<ConferenceConfig['tokbox']>(),
);
export const initSuccess = createAction(
  `/${waitingRoomFeatureKey}/initSuccess`,
);
export const initError = createAction(
  `/${waitingRoomFeatureKey}/initError`,
  props<{ error: any }>(),
);
export const reconnected = createAction(
  `/${waitingRoomFeatureKey}/reconnected`,
);

export const startWaitingForGuests = createAction(
  `/${waitingRoomFeatureKey}/startWaitingForGuests`,
);
export const guestKnocked = createAction(
  `/${waitingRoomFeatureKey}/guestKnocked`,
  props<{ knockData: KnockData }>(),
);

export const guestKnockCanceled = createAction(
  `/${waitingRoomFeatureKey}/guestKnockCanceled`,
  props<{ id: string }>(),
);

export const guestAdmitted = createAction(
  `/${waitingRoomFeatureKey}/guestAdmitted`,
  props<{ token: string; appointmentId: string }>(),
);
export const guestAdmissionDenied = createAction(
  `/${waitingRoomFeatureKey}/guestAdmissionDenied`,
);

export const admitGuest = createAction(
  `/${waitingRoomFeatureKey}/admitGuest`,
  props<{ id: string; name: string }>(),
);

export const admitGuestSuccess = createAction(
  `/${waitingRoomFeatureKey}/admitGuestSuccess`,
  props<{ id: string; name: string }>(),
);

export const admitGuestError = createAction(
  `/${waitingRoomFeatureKey}/admitGuestError`,
  props<{ id: string; name: string; error: any }>(),
);

export const denyGuest = createAction(
  `/${waitingRoomFeatureKey}/denyGuest`,
  props<{ id: string; name: string }>(),
);

export const denyGuestSuccess = createAction(
  `/${waitingRoomFeatureKey}/denyGuestSuccess`,
  props<{ id: string; name: string }>(),
);

export const denyGuestError = createAction(
  `/${waitingRoomFeatureKey}/denyGuestError`,
  props<{ error: any }>(),
);

export const knock = createAction(
  `/${waitingRoomFeatureKey}/knock`,
  props<{ knockData: KnockData }>(),
);

export const knockSuccess = createAction(
  `/${waitingRoomFeatureKey}/knockSuccess`,
  props<{ knockData: KnockData }>(),
);

export const knockError = createAction(
  `/${waitingRoomFeatureKey}/knockError`,
  props<{ error: any }>(),
);

export const cancelKnock = createAction(
  `/${waitingRoomFeatureKey}/cancelKnock`,
  props<{ id: string }>(),
);

export const cancelKnockSuccess = createAction(
  `/${waitingRoomFeatureKey}/cancelKnockSuccess`,
  props<{ id: string }>(),
);

export const cancelKnockError = createAction(
  `/${waitingRoomFeatureKey}/cancelKnockError`,
  props<{ error: any }>(),
);

export const guestKnockingDisconnected = createAction(
  `/${waitingRoomFeatureKey}/guestKnockingDisconnected`,
  props<{ id: string }>(),
);

export const rejectKnock = createAction(
  `/${waitingRoomFeatureKey}/rejectKnock`,
  props<{ knockData: KnockData; reason: string }>(),
);

export const knockRejected = createAction(
  `/${waitingRoomFeatureKey}/knockRejected`,
  props<{ reason: string }>(),
);

export const rejectKnockSuccess = createAction(
  `/${waitingRoomFeatureKey}/rejectKnockSuccess`,
  props<{ knockData: KnockData; reason: string }>(),
);

export const rejectKnockError = createAction(
  `/${waitingRoomFeatureKey}/rejectKnockError`,
  props<{ error: any }>(),
);
